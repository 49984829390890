import { ComponentType, useEffect } from "react"
import { useAuth } from "https://framer.com/m/hooks-cRhB.js"

export function withUser(Component): ComponentType {
    return (props) => {
        const { user, isLoggedIn, loading, error } = useAuth()
        const { variant, link, ...rest } = props

        if (!user) {
            return <Component {...props} />
        }

        const userProps = {
            variant: "gzG5gE5K_",
        }
        return <Component {...rest} {...userProps} link="/dashboard" />
    }
}

export function withUserProfile(Component): ComponentType {
    return (props) => {
        const { user } = useAuth()
        const { ...rest } = props

        if (!user) {
            return <Component {...props} />
        }

        const userProps = {
            photoURL: user.photoURL,
            displayName: user.displayName ?? getInitials(user.email),
            email: user.email,
        }

        return (
            <Component {...rest} {...userProps} hasPhoto={userProps.photoURL} />
        )
    }
}

function getInitials(fullName) {
    let firstInitial = fullName.charAt(0).toUpperCase()
    let lastInitial = fullName.charAt(1).toUpperCase()
    return firstInitial + lastInitial
}
